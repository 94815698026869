<template>
  <div>
    <div class="button" @click="onClick">
      <a-icon
        type="unordered-list"
        style="font-size: 16px; margin-bottom: 8px"
      />
      <span>原项目</span>
    </div>

    <Modal :visible="visible" @setVisible="setVisible" />
  </div>
</template>

<script>
import Modal from "./modal.vue";
export default {
  components: {
    Modal,
  },

  data() {
    return {
      visible: false, // 原项目详情弹窗
    };
  },

  methods: {
    onClick() {
      this.visible = true;
    },
    setVisible(payload) {
      this.visible = payload;
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: 1px solid #1890ff;
  background-color: #1890ff;
  color: #fff;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>