<template>
  <div>
    <div class="pane">
      <div>
        <a-breadcrumb class="breadcrumb">
          <a-breadcrumb-item>
            <router-link to="/produce/progress">项目列表</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>项目详情</a-breadcrumb-item>
        </a-breadcrumb>
        <div style="font-size: 20px">{{ project.name }}</div>
      </div>
      <div class="left">
        <UpdateApply />
        <FatherProject v-if="project.pid" />
        <CloneProject />
        <PrintProject />
        <ClearProject />
        <DeleteProject />
      </div>
    </div>

    <div class="container">
      <div class="wrapper">
        <Info />
      </div>
      <div class="wrapper">
        <Collection />
      </div>
      <div class="wrapper">
        <SubPackage />
      </div>

      <div class="wrapper">
        <ProgressCategory />
      </div>
      <div class="wrapper">
        <Design />
      </div>

      <div class="grid-4">
        <List />
      </div>
      <div>
        <Log />
      </div>

      <div class="wrapper">
        <Title title="计算公式"></Title>
        <div style="padding: 24px">
          <template v-if="project.subpackageAmount">
            <h3>历史分包总额</h3>
            <p>
              <span style="font-size: 24px; color: #1890ff; padding-right: 4px">
                <Money :money="project.subpackageAmount" />
              </span>
              万元
            </p>
          </template>

          <h3>生产院</h3>
          <p>
            1、2021年前已产生进度的项目
            （合同额-历史分包总额）*本次确认阶段-（现分包总额-历史分包总额）*（本次确认阶段比例/1-历史已确认阶段总比例）*区域打分
          </p>
          <p>2、2021年前未产生进度项目 （合同额-分包总额）*本次确认阶段比例</p>

          <h3>承包院</h3>
          <p>
            1、2021年前已产生进度的项目
            （合同额-历史必要分包总额）*本次确认阶段-（现必要分包总额-历史必要分包总额）*（本次确认阶段比例/1-历史已确认阶段总比例）*区域打分
          </p>
          <p>
            2、2021年前未产生进度项目 （合同额-必要分包总额）*本次确认阶段比例
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";

import UpdateApply from "./update-apply";
import FatherProject from "./father-project";
import CloneProject from "./clone-project";
import PrintProject from "./print-project";
import ClearProject from "./clear-project";
import DeleteProject from "./delete-project";

import Info from "./info/index";
import Collection from "./collection";
import SubPackage from "./subpackage";
import ProgressCategory from "./progress/category";
import Design from "./design";
import List from "./list";
import Log from "./log";

import Title from "./title";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "progressDetail",

  mixins: [watermark],

  components: {
    UpdateApply,
    FatherProject,
    CloneProject,
    PrintProject,
    ClearProject,
    DeleteProject,

    Info,
    Collection,
    SubPackage,
    ProgressCategory,
    Design,
    List,
    Log,

    Title,
  },

  data() {
    return {
      id: null,
    };
  },

  computed: {
    ...mapState("projectDetail", ["project"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.setId(id);
      this.fetchProject(); // 获取项目详情
      this.fetchPhaseList(); // 获取阶段列表
      this.fetchPhaseStatistic(); // 获取所有阶段的产值分配情况
    }
  },

  mounted() {
    // 设置水印
    const wrapper = document.querySelector(".wrapper");
    const grid4 = document.querySelector(".grid-4");
    if (wrapper && grid4) {
      this.setWatermark(wrapper);
      this.setWatermark(grid4);
    }
  },

  methods: {
    ...mapMutations("projectDetail", ["setId"]),
    ...mapActions("projectDetail", [
      "fetchProject",
      "fetchPhaseList",
      "fetchPhaseStatistic",
    ]),
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 0;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
}

.breadcrumb {
  margin-bottom: 12px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  &>div {
    background-color: #fff;
  }

  .wrapper {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .grid-4 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
</style>