<template>
  <a-modal :visible="visible" title="发起审批" @ok="ok" @cancel="cancel" width="800px">
    <a-row :gutter="[8, 8]">
      <a-col :span="24">
        <div class="title">项目阶段进度</div>
        <a-table bordered :data-source="phaseList" :pagination="false">
          <a-table-column title="阶段名" data-index="phaseName" align="center" />
          <a-table-column title="占比" align="right">
            <template slot-scope="text">{{ text.phasePercent }}%</template>
          </a-table-column>
          <a-table-column title="进度" align="right">
            <template slot-scope="text">{{ text.progress }}%</template>
          </a-table-column>
        </a-table>
      </a-col>
      <a-col :span="24">
        <div class="title">项目收款进度</div>
        <a-table
          bordered
          :data-source="planReceiptList"
          :pagination="false"
          rowKey="id"
          :row-selection="{
            selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <a-table-column title="收款进度编号" data-index="code" align="center" />
          <a-table-column title="收款进度名称" data-index="name" />

          <a-table-column title="收款比例" align="right">
            <template slot-scope="text">
              <div>
                <span>{{ text.ratio }}%</span>
              </div>
            </template>
          </a-table-column>

          <a-table-column title="收款金额(万元)" align="right">
            <template slot-scope="text">
              <Money :money="text.planReceipt" />
            </template>
          </a-table-column>
        </a-table>
      </a-col>
      <a-col :span="24">
        <div class="title">审批意见</div>
        <a-textarea placeholder="请输入审批意见" v-model="comment" :auto-size="{ minRows: 3 }" />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      planReceiptList: [],
      selectedRowKeys: [],
      comment: ""
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        if (
          this.project.contract &&
          Array.isArray(this.project.contract.planReceiptList)
        ) {
          this.planReceiptList = this.project.contract.planReceiptList;
          this.selectedRowKeys = this.planReceiptList
            .filter(item => item.isFinished)
            .map(item => item.id);
        } else {
          this.planReceiptList = [];
          this.selectedRowKeys = [];
        }
      }
    }
  },

  computed: {
    ...mapState("projectDetail", ["phaseList", "project"])
    // planReceiptList() {
    //   if (
    //     this.project.contract &&
    //     Array.isArray(this.project.contract.planReceiptList)
    //   ) {
    //     return this.project.contract.planReceiptList;
    //   } else {
    //     return [];
    //   }
    // },
  },
  methods: {
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    ok() {
      this.$emit("setVisible", false);
      this.$emit("done", {
        selectedRowKeys: this.selectedRowKeys,
        comment: this.comment
      });
    },
    cancel() {
      this.$emit("setVisible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  margin-bottom: 8px;
}
</style>