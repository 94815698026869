var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"between"},[_c('total-output-value',{attrs:{"value":_vm.item.totalAmount,"cost":_vm.item.js3Cost}}),(
        Array.isArray(_vm.item.progressList) &&
        _vm.item.progressList[0] &&
        typeof _vm.item.progressList[0].phaseCode === 'string' &&
        _vm.item.progressList[0].phaseCode.indexOf('adjustment_') === 0 &&
        _vm.item.progressList[0].isConfigureHistory === false
      )?_c('div',{staticClass:"history"},[_vm._v(" 历史进度未确认，请联系经管部 ")]):_vm._e(),(
        Array.isArray(_vm.item.progressList) &&
        _vm.item.progressList[0] &&
        typeof _vm.item.progressList[0].phaseCode === 'string' &&
        _vm.item.progressList[0].phaseCode.indexOf('adjustment_') === 0 &&
        _vm.item.progressList[0].isConfigureHistory === true
      )?_c('div',[(_vm.$getPermission('/produce/progress/adjust-allocate'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(
            '/produce/progress/adjust-allocate?id=' + _vm.item.progressList[0].id
          )}}},[_vm._v("产值分配")]):_vm._e()],1):_vm._e()],1),_c('Padding',{attrs:{"size":"large"}}),_c('StageList',{attrs:{"list":Array.isArray(_vm.item.majorList) ? _vm.item.majorList : []}}),_c('Padding',{attrs:{"size":"large"}}),_c('DepartmentList',{attrs:{"list":Array.isArray(_vm.item.deptList) ? _vm.item.deptList : []}}),_c('Padding',{attrs:{"size":"large"}}),_c('MajorList',{attrs:{"list":Array.isArray(_vm.item.subMajorList) ? _vm.item.subMajorList : [],"majorList":Array.isArray(_vm.item.majorList) ? _vm.item.majorList : []}}),_c('Padding',{attrs:{"size":"large"}}),_c('StaffList',{attrs:{"list":Array.isArray(_vm.item.staffOutputList) ? _vm.item.staffOutputList : []}}),_c('Padding',{attrs:{"size":"large"}}),_c('MajorFileList',{attrs:{"list":Array.isArray(_vm.item.progressList) ? _vm.item.progressList : []}}),_c('Padding',{attrs:{"size":"large"}}),_c('FileList',{attrs:{"list":Array.isArray(_vm.item.progressList) ? _vm.item.progressList : []}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }