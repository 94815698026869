<template>
  <div
    class="button"
    @click="deleteProject"
    v-if="$getPermission($route.path + '/project-delete')"
  >
    <a-icon type="delete" style="font-size: 16px; margin-bottom: 8px" />
    <span>删除</span>
  </div>
</template>
<script>
import { remove } from "@/api/project";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("projectDetail", ["id"]),
  },
  methods: {
    deleteProject() {
      const that = this;

      this.$confirm({
        title: "确认要删除这个项目吗？",
        onOk() {
          remove({
            id: that.id,
          }).then(() => {
            that.$router.go(-1);
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: 1px solid rgb(247, 45, 45);
  background-color: rgb(247, 45, 45);
  color: #fff;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>