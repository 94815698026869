<template>
  <div class="button" @click="cloneProject" v-if="$getPermission($route.path + '/clone-project')">
    <a-icon type="copy" style="font-size: 16px; margin-bottom: 8px" />
    <span>克隆项目</span>

    <a-modal v-model="visible" title="克隆项目" width="400px">
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          确定
        </a-button>
      </template>
      <div style="margin-bottom: 8px">合同金额(万元)</div>
      <a-input-number placeholder="请输入金额" v-model="money" style="width: 100%" />
      <div style="margin-top: 12px; margin-bottom: 8px">备注</div>
      <a-textarea :auto-size="{ minRows: 3 }" placeholder="请输入备注" v-model="remarks" />
    </a-modal>
  </div>
</template>
<script>
import { clone } from "@/api/project";
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      money: null,
      remarks: null,
    };
  },

  computed: {
    ...mapState("projectDetail", ["project"]),
  },

  methods: {
    cloneProject() {
      this.visible = true;
    },

    handleOk() {
      if (!this.money) {
        this.$message.error("请输入合同金额！");
        return;
      }

      this.loading = true;

      clone({
        contractId: this.project.contract ? this.project.contract.id : null,
        projectAmount: this.money * 10000,
        remarks: this.remarks,
      })
        .then(() => {
          this.handleCancel();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.visible = false;
      this.money = null;
      this.remarks = null;
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: 1px solid #f78626;
  background-color: #f78626;
  color: #fff;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>