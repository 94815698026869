<template>
  <div class="button" @click="print">
    <a-icon type="printer" style="font-size: 16px; margin-bottom: 8px" />
    <span>打印</span>
  </div>
</template>
<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: 1px solid #999;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>