<template>
  <div>
    <Title title="分包明细" />

    <div style="padding: 12px">
      <a-table bordered :data-source="list" :pagination="false">
        <a-table-column key="name" title="分包名称" data-index="name">
        </a-table-column>
        <a-table-column key="type" title="分包类型">
          <template slot-scope="text">
            <div class="left">
              <DataDictFinder
                v-if="text.isneeded === 1"
                dictType="subType"
                :dictValue="text.type"
              />
              <DataDictFinder
                v-if="text.isneeded === 0"
                dictType="notNeedSubType"
                :dictValue="text.type"
              />

              <DataDictFinder
                v-if="text.isneeded === 2"
                dictType="generalSubType"
                :dictValue="text.type"
              />
              <template v-if="text.major">
                <span> - </span>
                <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
              </template>
            </div>
          </template>
        </a-table-column>
        <a-table-column key="isNeed" title="是否必要">
          <template slot-scope="text">
            <div>
              <a-tag v-if="text.isneeded === 0" color="#f00"
                >设计类-非必要分包</a-tag
              >
              <a-tag v-if="text.isneeded === 1" color="#1890ff"
                >设计类-必要分包</a-tag
              >
              <a-tag v-if="text.isneeded === 2" color="green"
                >总承包类分包</a-tag
              >
            </div>
          </template>
        </a-table-column>

        <a-table-column
          key="companyName"
          title="公司"
          data-index="companyName"
          width="240px"
          ellipsis="true"
        ></a-table-column>

        <a-table-column
          key="status"
          title="分包状态"
          data-index="status"
          align="center"
        >
          <template slot-scope="status">
            <div>
              <DataDictFinder
                dictType="subpackageStatus"
                :dictValue="status"
                iconType="badge"
              />
            </div>
          </template>
        </a-table-column>
        <a-table-column
          key="planAmount"
          title="分包金额(万元)"
          data-index="planAmount"
          align="right"
        >
          <template slot-scope="planAmount">
            <Money :money="planAmount" />
          </template>
        </a-table-column>

        <a-table-column
          v-if="!project.pid"
          key="control"
          title="操作"
          align="center"
          width="280px"
          fixed="right"
        >
          <template slot-scope="text">
            <PermissionButton path="update-subpackage">
              <a href="#" @click.prevent="edit(text)"> 调整 </a>
            </PermissionButton>
          </template>
        </a-table-column>
      </a-table>

      <a-modal
        title="分包详情"
        :visible="visible"
        width="483px"
        @cancel="cancel"
        @ok="ok"
      >
        <a-form
          :colon="false"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-item label="分包金额(万元)">
            <a-input-number v-model="activeSubpackage.planAmount" :min="0" />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import { mapActions, mapState } from "vuex";
import { updateSubpackageMoney } from "@/api/project";
export default {
  components: {
    Title,
  },

  computed: {
    ...mapState("projectDetail", ["project"]),
    list() {
      if (Array.isArray(this.project.subPackages)) {
        return this.project.subPackages;
      } else {
        return [];
      }
    },
  },

  data() {
    return {
      visible: false,
      activeSubpackage: {},
    };
  },

  methods: {
    ...mapActions("projectDetail", ["fetchProject"]),

    edit(text) {
      this.activeSubpackage = {
        ...text,
        planAmount: Number((text.planAmount / 10000).toFixed(4)),
      };
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    ok() {
      updateSubpackageMoney([
        {
          ...this.activeSubpackage,
          planAmount: this.activeSubpackage.planAmount * 10000,
        },
      ]).then(() => {
        this.fetchProject();
        this.visible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .money {
    margin-bottom: 16px;
  }
}
</style>