<template>
  <div>
    <Title title="合同收款项" />
    <div class="wrapper">
      <a-table bordered :dataSource="planReceiptList" :pagination="false">
        <a-table-column title="编号" align="center">
          <template slot-scope="text">{{ text.code }}</template>
        </a-table-column>
        <a-table-column title="收款项名称">
          <template slot-scope="text">{{ text.name }}</template>
        </a-table-column>
        <a-table-column title="收款比例" align="right">
          <template slot-scope="text">
            <span>{{ text.ratio }}%</span>
          </template>
        </a-table-column>
        <a-table-column title="收款金额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.planReceipt" />
          </template>
        </a-table-column>

        <a-table-column title="进度确认" align="center">
          <template slot-scope="text, record, index">
            <a-checkbox
              :checked="text.isFinished === 1 ? true : false"
              @change="(e) => onChange(e, index)"
            />
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import { save } from "@/api/contract/collection";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Title
  },
  data() {
    return {};
  },

  computed: {
    ...mapState("projectDetail", ["phaseList", "project"]),
    planReceiptList() {
      if (
        this.project.contract &&
        Array.isArray(this.project.contract.planReceiptList)
      ) {
        return this.project.contract.planReceiptList;
      } else {
        return [];
      }
    }
  },

  methods: {
    ...mapActions("projectDetail", ["fetchProject", "fetchPhaseList"]),

    onChange(e, index) {
      console.log("e", e);
      const hide = this.$message.loading("修改中", 0);
      save({
        contractId: this.project.contractId,
        receiptList: this.planReceiptList.map((item, idx) => {
          if (index === idx) {
            return {
              ...item,
              isFinished: e.target.checked ? 1 : 0
            };
          }
          return item;
        })
      })
        .then(() => {
          this.fetchProject(); // 获取项目详情
          this.fetchPhaseList(); // 获取阶段列表
        })
        .finally(() => {
          hide();
        });
    }
  }
};
</script>


<style lang="less" scoped>
.wrapper {
  background-color: #fff;
  padding: 12px;
}
</style>