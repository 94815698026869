<template>
  <div>
    <Title title="日志" />

    <div style="padding: 12px">
      <a-list
        item-layout="horizontal"
        :data-source="list"
        :pagination="{
          pageSize: 10,
        }"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="item.logDate">
            <div slot="title">
              {{ item.businessContent }}
            </div>
            <span slot="avatar">
              <a-avatar v-if="item.header" :src="item.header" />
              <DefaultAvatar :name="item.userName" v-else />
            </span>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>

<script>
import DefaultAvatar from "@/components/default-avatar";
import Title from "../title";
import { mapState } from "vuex";
import { fetchList } from "@/api/setting/log";
export default {
  components: {
    Title,
    DefaultAvatar,
  },

  data() {
    return {
      current: 1,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapState("projectDetail", ["project"]),
  },

  watch: {
    project() {
      console.log("fetch project log");
      this.getList();
    },
  },
  methods: {
    getList() {
      fetchList({
        pageNum: this.current,
        pageSize: 100,
        conds: [
          {
            condName: "businessKey",
            condValue: [
              `Project:${this.project.id}`,
              `Contract:${
                this.project.contract ? this.project.contract.id : ""
              }`,
            ],
          },
        ],
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = res.totalSize;
        }
      });
    },
  },
};
</script>
