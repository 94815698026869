<template>
  <div
    class="value"
    :style="{ display: display, fontSize: fontSize + 'px', fontWeight: 400 }"
  >
    <span>{{ Array.isArray(amount) ? amount[0] : amount }}</span>
    <span
      :style="{ fontSize: smallFontSize + 'px' }"
      v-if="Array.isArray(amount)"
      >.{{ amount[1] }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    money: {
      type: Number,
      default: 0,
    },
    display: {
      type: String,
      default: "block",
    },
    fontSize: {
      type: Number,
      default: 24,
    },
    smallFontSize: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    amount() {
      if (this.money) {
        const list = (this.money / 10000).toFixed(2).split(".");
        return list;
      } else {
        return "--";
      }

      // if (/\./.test(this.money)) {
      //     const list = String(this.money).split('.');
      //     if (list.length === 2) {
      //         return list[0]
      //     } else {
      //         return ''
      //     }
      // } else {
      //     return (this.money / 10000)
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.value {
  color: #1890ff;
}
</style>