<template>
  <div class="progress-wrapper">
    <a-button type="primary" style="width: 170px">{{
      phase.phaseName
    }}</a-button>

    <a-divider type="vertical" />

    <div style="flex: 1">
      <div class="left">
        <span>当前进度：</span>
        <template v-if="use">
          <a-input-number
            :value="phase.progress"
            @change="onProgressChange"
            :formatter="$ratioFormatter"
            :min="0"
            :max="100"
            style="margin-right: 8px"
            :disabled="!$getPermission('/produce/progress/save')"
          />
          <a-button
            type="primary"
            @click="save"
            :loading="saveLoading"
            v-if="
              phase.progress !== 100 && $getPermission('/produce/progress/save')
            "
            style="margin-right: 8px"
            >保存</a-button
          >
        </template>

        <template v-else>
          <span class="progress">{{ phase.progress }} %</span>

          <a-tooltip
            v-if="isHistoryProject"
            title="历史项目进度，无法查看人员配置"
          >
            <a-button
              type="primary"
              size="small"
              style="margin-left: 24px"
              :disabled="true"
            >
              进度配置
            </a-button>
          </a-tooltip>

          <a-button
            v-else
            type="primary"
            size="small"
            @click="$emit('setVisible', true)"
            style="margin-left: 24px"
          >
            进度配置
          </a-button>
        </template>

        <a-button
          icon="upload"
          type="primary"
          v-if="phase.progress === 100 && use"
          @click="add"
          >上传确认资料</a-button
        >

        <a-button
          type="link"
          v-if="!showFile"
          @click="showFile = true"
          style="margin-left: auto"
        >
          <span> 查看确认文件（{{ showList.length }}） </span>
          <a-icon type="down" />
        </a-button>
        <a-button
          type="link"
          v-else
          @click="showFile = false"
          style="margin-left: auto"
        >
          <span> 隐藏确认文件 </span>
          <a-icon type="up" />
        </a-button>
      </div>

      <Padding v-if="showFile" />

      <div style="background: #fff" v-if="showFile">
        <a-table bordered :data-source="showList" :pagination="false">
          <a-table-column title="资料类型" data-index="typeValue">
          </a-table-column>
          <a-table-column title="文件名称">
            <template slot-scope="text">
              <a download target="_blank" :href="text.filePath">{{
                text.fileName ? text.fileName : "--"
              }}</a>
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <span style="color: #f00; cursor: pointer" @click="remove(text)"
                >删除</span
              >
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>

    <UploadModal
      :visible="visible"
      @setVisible="setVisible"
      :phase="phase"
      @save="onSave"
    />
  </div>
</template>

<script>
import { saveProgress, deleteFile } from "@/api/project";
import { mapActions, mapGetters, mapState } from "vuex";
import UploadModal from "./upload-modal";
export default {
  props: {
    phaseList: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
    },
    phase: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    UploadModal,
  },

  data() {
    return {
      visible: false,
      saveLoading: false, // 保存按钮 loading
      showFile: false, // 是否显示文件
    };
  },

  computed: {
    ...mapState("project", ["project"]),
    ...mapGetters("setting", ["findDataDict"]),

    fileTypeList() {
      return this.findDataDict("fileType");
    },

    showList() {
      // 展示在页面上的文件列表，需要添加文件类型，从数据字典中查找
      const typeObj = this.fileTypeList.find(
        (item) => item.value === this.phase.phaseCode
      );
      if (typeObj && Array.isArray(typeObj.children)) {
        let newList = [];
        for (let i = 0; i < this.list.length; i++) {
          const element = this.list[i];
          const result = typeObj.children.find(
            (item) => item.value === element.type
          );

          if (result) {
            newList.push({
              ...element,
              typeValue: result.name,
            });
          }
        }

        return newList;
      } else {
        return this.list;
      }
    },

    // 上传的文件列表
    list() {
      return Array.isArray(this.phase.outputList) ? this.phase.outputList : [];
    },

    // 是否可以点击保存
    use() {
      return (
        this.phase.phaseProgressStatus !== "approved" &&
        this.phase.phaseProgressStatus !== "reviewing"
      );
    },

    // 这个阶段是否属于历史项目
    isHistoryProject() {
      return String(this.phase.legacy) === "1";
    },
  },

  methods: {
    ...mapActions("projectDetail", ["fetchPhaseList"]),

    add() {
      this.visible = true;
    },

    onProgressChange(value) {
      this.$emit("changeProgress", {
        index: this.index,
        value,
      });
    },

    onSave() {
      this.save(); // 用户点了上传文件的确定按钮，先把 100 的进度给提交掉，这样用户就不用点保存了。
    },

    setVisible(payload) {
      this.visible = payload;
    },

    remove(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个文件吗？",
        onOk() {
          deleteFile({
            id: text.id,
          }).then(() => {
            that.fetchPhaseList();
          });
        },
      });
    },

    save() {
      if (
        typeof this.phase.progress !== "number" ||
        this.phase.progress > 100 ||
        this.phase.progress < 0
      ) {
        this.$message.error("请输入合法的进度值！");
        return;
      }

      this.saveLoading = true;

      saveProgress([
        {
          id: this.phase.id,
          progress: this.phase.progress,
        },
      ])
        .then(() => {
          this.fetchPhaseList();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    startApproval() {
      this.$router.push("/oa/approval");
    },
  },
};
</script>

<style lang="less" scoped>
.progress-wrapper {
  padding: 12px;
  background-color: #f5f5f5;
  display: flex;
  margin-bottom: 12px;
}
.progress {
  font-size: 16px;
  font-weight: bold;
  color: #1890ff;
}
</style>






