<template>
  <div class="wrapper">
    <div class="between">
      <total-output-value :value="item.totalAmount" :cost="item.js3Cost" />
      <div
        class="history"
        v-if="
          Array.isArray(item.progressList) &&
          item.progressList[0] &&
          typeof item.progressList[0].phaseCode === 'string' &&
          item.progressList[0].phaseCode.indexOf('adjustment_') === 0 &&
          item.progressList[0].isConfigureHistory === false
        "
      >
        历史进度未确认，请联系经管部
      </div>
      <div
        v-if="
          Array.isArray(item.progressList) &&
          item.progressList[0] &&
          typeof item.progressList[0].phaseCode === 'string' &&
          item.progressList[0].phaseCode.indexOf('adjustment_') === 0 &&
          item.progressList[0].isConfigureHistory === true
        "
      >
        <a-button
          v-if="$getPermission('/produce/progress/adjust-allocate')"
          @click="
            $router.push(
              '/produce/progress/adjust-allocate?id=' + item.progressList[0].id
            )
          "
          type="primary"
          >产值分配</a-button
        >
      </div>
    </div>

    <Padding size="large" />

    <StageList :list="Array.isArray(item.majorList) ? item.majorList : []" />

    <Padding size="large" />

    <DepartmentList :list="Array.isArray(item.deptList) ? item.deptList : []" />

    <Padding size="large" />

    <MajorList
      :list="Array.isArray(item.subMajorList) ? item.subMajorList : []"
      :majorList="Array.isArray(item.majorList) ? item.majorList : []"
    />

    <Padding size="large" />

    <StaffList
      :list="Array.isArray(item.staffOutputList) ? item.staffOutputList : []"
    />
    <Padding size="large" />

    <MajorFileList
      :list="Array.isArray(item.progressList) ? item.progressList : []"
    />

    <Padding size="large" />

    <FileList
      :list="Array.isArray(item.progressList) ? item.progressList : []"
    />
  </div>
</template>

<script>
import TotalOutputValue from "./components/total-output-value.vue";
import StaffList from "./components/staff-list.vue";
import StageList from "./components/stage-list.vue";
import DepartmentList from "./components/department-list.vue";
import MajorList from "./components/major-list.vue";
import MajorFileList from "./components/major-file-list.vue";
import FileList from "./components/file-list.vue";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    TotalOutputValue,
    StaffList,
    StageList,
    MajorList,
    MajorFileList,
    FileList,
    DepartmentList,
  },
};
</script>


<style lang="less" scoped>
.history {
  font-weight: bold;
  font-size: 14px;
}
</style>