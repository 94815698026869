<template>
  <div>
    <div :id="id" style="width: 100%; height: 150px"></div>
    <div class="center text">项目进度</div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },

    progress: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    echarts.use([GaugeChart, CanvasRenderer]);

    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);

    const option = {
      series: [
        {
          type: "gauge",
          progress: {
            show: true,
            width: 8,
          },
          axisLine: {
            lineStyle: {
              width: 8,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            length: 8,
            lineStyle: {
              width: 2,
              color: "#d9d9d9",
            },
          },
          axisLabel: {
            distance: 12,
            color: "#999",
            fontSize: 0,
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 14,
            itemStyle: {
              borderWidth: 5,
            },
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            fontSize: 20,
            offsetCenter: [0, "70%"],
            formatter: this.$ratioFormatter,
          },
          data: [
            {
              value: this.progress,
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);

    this.chart = myChart;
  },

  watch: {
    progress(newValue, oldValue) {
      if (newValue !== oldValue) {
        const option = {
          series: [
            {
              type: "gauge",
              progress: {
                show: true,
                width: 8,
              },
              axisLine: {
                lineStyle: {
                  width: 8,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                length: 8,
                lineStyle: {
                  width: 2,
                  color: "#999",
                },
              },
              axisLabel: {
                distance: 12,
                color: "#999",
                fontSize: 0,
              },
              anchor: {
                show: true,
                showAbove: true,
                size: 14,
                itemStyle: {
                  borderWidth: 5,
                },
              },
              title: {
                show: false,
              },
              detail: {
                valueAnimation: true,
                fontSize: 20,
                offsetCenter: [0, "70%"],
                formatter: this.$ratioFormatter,
              },
              data: [
                {
                  value: this.progress,
                },
              ],
            },
          ],
        };

        option && this.chart.setOption(option);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
</style>