<template>
  <div>
    <PermissionButton path="update-apply">
      <div @click="updateApply" class="button">
        <a-icon type="edit" style="font-size: 16px; margin-bottom: 8px" />
        <span>调整申请</span>
      </div>
    </PermissionButton>

    <a-modal
      title="调整申请"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
      >
        <a-form-item label="申请类型">
          <a-select
            v-decorator="[
              'type',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="申请内容">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'remarks',
              {
                rules: [{ required: true, message: '请填写！' }],
              },
            ]"
          />
        </a-form-item>

        <a-divider />

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >提交</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// 调整申请
import { apply } from "@/api/project";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("productAdjustType");
    },

    ...mapState("projectDetail", ["project"]),
  },

  methods: {
    updateApply() {
      this.visible = true;
    },

    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          apply({
            projectId: this.project.id,
            ...values,
          }).then(() => {
            this.saveLoading = false;
            this.cancel();
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.button {
  border: 1px solid #1890ff;
  color: #fff;
  background-color: #1890ff;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>