<template>
  <a-modal :title="title" :visible="visible" @cancel="cancel" :footer="null" width="1400px">
    <div style="padding: 24px 0">
      <a-row>
        <a-col :span="12">
          <div class="info">
            <a-descriptions :column="2">
              <a-descriptions-item label="设计编号">
                {{ fatherProject.designCode }}
              </a-descriptions-item>
              <a-descriptions-item label="管理级别">
                <DataDictFinder dictType="project-type" :dictValue="project.projectScale" />
              </a-descriptions-item>
              <a-descriptions-item label="区域总经理">
                {{
                                fatherProject.contract
                                ? fatherProject.contract.areaMasterName
                                : "--"
                                }}
              </a-descriptions-item>
              <a-descriptions-item label="生产院长">
                {{
                                fatherProject.contract
                                ? fatherProject.contract.productionUnitMaster
                                : "--"
                                }}
              </a-descriptions-item>
              <a-descriptions-item label="生产负责人">
                {{ fatherProject.chargerName }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="statistic-wrapper">
            <a-row>
              <a-col :span="8">
                <div class="statistic">
                  <div class="label">合同金额(万)</div>
                  <Money :money="fatherProject.projectAmount" />
                </div>
              </a-col>
              <a-col :span="8">
                <div class="statistic">
                  <div class="label">合同净额(万)</div>
                  <Money :money="fatherProject.projectPureAmount" />
                </div>
              </a-col>
            </a-row>

            <div style="padding-top: 12px"></div>

            <a-row>
              <a-col :span="6">
                <div class="statistic">
                  <div class="label">分包总额(万)</div>
                  <Money :money="subPackageAmount.all" />
                </div>
              </a-col>

              <a-col :span="6">
                <div class="statistic">
                  <div class="label">设计类-必要分包额(万)</div>
                  <Money :money="subPackageAmount.necessary" />
                </div>
              </a-col>

              <a-col :span="6">
                <div class="statistic">
                  <div class="label">设计类-非必要分包额(万)</div>
                  <Money :money="subPackageAmount.all - subPackageAmount.necessary" />
                </div>
              </a-col>
              <a-col :span="6">
                <div class="statistic">
                  <div class="label">总承包类分包额(万)</div>
                  <Money :money="0" />
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>

      <div style="padding-top: 12px"></div>

      <Subpackage :fatherProject="fatherProject" @update="update" />
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import { fetchDetail } from "@/api/project";
import Money from "./money";
import Subpackage from "./subpackage.vue";
export default {
  components: {
    Money,
    Subpackage,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: "",
      fatherProject: {},
    };
  },

  computed: {
    ...mapState("projectDetail", ["project"]),

    subPackageAmount() {
      let all = 0;
      let necessary = 0;
      if (Array.isArray(this.fatherProject.subPackages)) {
        this.fatherProject.subPackages.forEach((item) => {
          if (item.actualAmount) {
            all += item.actualAmount;
          } else {
            all += item.planAmount;
          }

          if (item.isneeded) {
            // 必要的
            if (item.actualAmount) {
              necessary += item.actualAmount;
            } else {
              necessary += item.planAmount;
            }
          }
        });
      }
      return {
        all,
        necessary,
      };
    },

    collectionProgress() {
      const { receivedAmount, projectAmount } = this.fatherProject;
      if (typeof receivedAmount === "number" && !isNaN(receivedAmount)) {
        return parseFloat((receivedAmount / projectAmount).toFixed(2)) * 100;
      } else {
        return 0;
      }
    },

    progressAmount() {
      const { progress, projectPureAmount } = this.fatherProject;
      return parseFloat(((projectPureAmount * progress) / 100).toFixed(2));
    },
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        this.getData();
      }
    },
  },

  methods: {
    getData() {
      fetchDetail({
        id: this.project.pid,
      }).then((res) => {
        if (res) {
          this.fatherProject = res;
          this.title = res.name;
        }
      });
    },

    cancel() {
      this.$emit("setVisible", false);
    },

    update() {
      this.getData();
    },
  },
};
</script>