<template>
  <div>
    <Title title="项目概况"> </Title>
    <div style="padding: 12px">
      <a-row type="flex" align="middle">
        <a-col :span="6">
          <div class="info">
            <a-descriptions :column="2">
              <a-descriptions-item label="设计编号">
                {{ project.designCode }}
              </a-descriptions-item>
              <a-descriptions-item label="管理级别">
                <DataDictFinder dictType="project-type" :dictValue="project.projectScale" />
              </a-descriptions-item>
            </a-descriptions>
            <a-divider :dashed="true" />

            <a-descriptions :column="2">
              <a-descriptions-item label="区域总经理">
                {{ project.contract ? project.contract.areaMasterName : "--" }}
              </a-descriptions-item>
              <a-descriptions-item label="责任部门">
                {{
                                project.contract
                                ? project.contract.productionUnitNameUnique
                                : "--"
                                }}
              </a-descriptions-item>
              <a-descriptions-item label="生产院长">
                {{
                                project.contract
                                ? project.contract.productionUnitMaster
                                : "--"
                                }}
              </a-descriptions-item>

              <a-descriptions-item label="生产负责人">
                {{
                                project.contract ? project.contract.productionMasterName : ""
                                }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="category">
            <a-divider type="vertical" :dashed="true" class="divider" />

            <div style="width: 180px">
              <progress-chart id="project" :progress="project.progress" />
            </div>

            <div class="statistic-wrapper">
              <a-row>
                <a-col :span="8">
                  <div class="statistic">
                    <div class="label">合同金额(万)</div>
                    <Money :money="project.projectAmount" />
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="statistic">
                    <div class="label">合同净额(万)</div>
                    <Money :money="project.projectPureAmount" />
                  </div>
                </a-col>
              </a-row>

              <div style="padding-top: 24px"></div>

              <a-row>
                <a-col :span="6">
                  <div class="statistic">
                    <div class="label">分包总额(万)</div>
                    <Money :money="subPackageAmount.all" />
                  </div>
                </a-col>

                <a-col :span="6">
                  <div class="statistic">
                    <div class="label">设计类-必要分包额(万)</div>
                    <Money :money="subPackageAmount.necessary" />
                  </div>
                </a-col>

                <a-col :span="6">
                  <div class="statistic">
                    <div class="label">设计类-非必要分包额(万)</div>
                    <Money :money="subPackageAmount.notNecessary" />
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="statistic">
                    <div class="label">总承包类分包额(万)</div>
                    <Money :money="subPackageAmount.other" />
                  </div>
                </a-col>
              </a-row>
            </div>

            <a-divider type="vertical" :dashed="true" class="divider" />
          </div>
        </a-col>

        <a-col :span="6">
          <div class="progress">
            <div class="title">
              <span>进度收入：</span>
              <Money v-if="progressAmount" :money="progressAmount" display="inline" :fontSize="14" :smallFontSize="12" />
              <span v-else>--</span>
              <span class="unit">万</span>
            </div>
            <div>
              <a-progress :percent="project.progress" status="active" stroke-linecap="square" :strokeWidth="17" />
            </div>
            <div style="padding-top: 24px"></div>
            <div class="title">
              <span>实收金额：</span>

              <Money v-if="project.receivedAmount" :money="project.receivedAmount" display="inline" :fontSize="14"
                :smallFontSize="12" />
              <span v-else>--</span>
              <span class="unit">万</span>
            </div>
            <div>
              <a-progress strokeColor="#F78626" :percent="collectionProgress" status="active" stroke-linecap="square"
                :strokeWidth="17" />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import Money from "./money";
import ProgressChart from "./progress-chart";
import { mapState } from "vuex";
export default {
  components: {
    Title,
    Money,
    ProgressChart,
  },

  computed: {
    ...mapState("projectDetail", ["project"]),
    subPackageAmount() {
      let all = 0;
      let notNecessary = 0;
      let necessary = 0;
      let other = 0;
      if (Array.isArray(this.project.subPackages)) {
        this.project.subPackages.forEach((item) => {
          if (item.actualAmount) {
            all += item.actualAmount;
          } else {
            all += item.planAmount;
          }

          if (item.isneeded === 0) {
            if (item.actualAmount) {
              notNecessary += item.actualAmount;
            } else {
              notNecessary += item.planAmount;
            }
          }

          if (item.isneeded === 1) {
            // 必要的
            if (item.actualAmount) {
              necessary += item.actualAmount;
            } else {
              necessary += item.planAmount;
            }
          }

          if (item.isneeded === 2) {
            if (item.actualAmount) {
              other += item.actualAmount;
            } else {
              other += item.planAmount;
            }
          }
        });
      }
      return {
        all,
        necessary,
        notNecessary,
        other,
      };
    },

    collectionProgress() {
      const { receivedAmount, projectAmount } = this.project;
      if (typeof receivedAmount === "number" && !isNaN(receivedAmount)) {
        return parseFloat((receivedAmount / projectAmount).toFixed(2)) * 100;
      } else {
        return 0;
      }
    },

    progressAmount() {
      const { progress, projectPureAmount } = this.project;
      return parseFloat(((projectPureAmount * progress) / 100).toFixed(2));
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding-right: 56px;
}

.category {
  height: 100%;
  display: flex;
  align-items: center;
}

.divider {
  height: 200px;
}

.statistic-wrapper {
  flex: 1;
  padding: 0 20px;

  .statistic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .label {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.progress {
  padding: 0 20px;

  .title {
    font-size: 14px;
    color: #333;
    margin-bottom: 16px;
  }

  .unit {
    font-size: 12px;
    margin-left: 2px;
  }
}
</style>