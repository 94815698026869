<template>
  <div
    class="button"
    @click="onClick"
    v-if="$getPermission($route.path + '/reset-project')"
  >
    <a-icon type="close-circle" style="font-size: 16px; margin-bottom: 8px" />
    <span>重置项目</span>
  </div>
</template>
<script>
import { clearData } from "@/api/project";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("projectDetail", ["project"]),
  },

  methods: {
    onClick() {
      const that = this;
      this.$confirm({
        title: "是否重置项目？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          clearData({
            id: that.project.id,
          }).then(() => {
            that.$close(that.$route.path);
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  border: 1px solid #f8461a;
  background-color: #f8461a;
  color: #fff;
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
}
</style>