<template>
  <div>
    <Title title="项目阶段" />
    <div style="padding: 12px">
      <a-table bordered :data-source="phaseList" :pagination="false">
        <a-table-column title="阶段名称" data-index="phaseName"></a-table-column>
        <a-table-column title="占比">
          <template slot-scope="text">
            <div>{{ text.phasePercent }}%</div>
          </template>
        </a-table-column>
        <a-table-column title="项目进度" data-index="progress" width="240px">
          <template slot-scope="progress">
            <a-progress :percent="typeof progress === 'number' ? progress : 0" />
          </template>
        </a-table-column>
        <a-table-column title="阶段进度审核状态" data-index="phaseProgressStatus" align="center">
          <template slot-scope="phaseProgressStatus">
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="phaseProgressStatus"
              iconType="badge"
              badgeType="color"
            />
          </template>
        </a-table-column>

        <a-table-column title="当年已确认可分配进度(万元)" align="right">
          <template slot-scope="text">
            <div v-if="text.progress === 100 && text.score">
              <Money :money="text.quota / text.score" />
            </div>
          </template>
        </a-table-column>

        <a-table-column title="区域打分" align="right">
          <template slot-scope="text">
            <div v-if="text.score">{{ text.score * 100 }}</div>
          </template>
        </a-table-column>

        <a-table-column title="当年已确认可分配进度可分配额(万元)" align="right">
          <template slot-scope="text">
            <div v-if="text.progress === 100">
              <Money :money="text.quota" />
            </div>
            <div v-else>--</div>
          </template>
        </a-table-column>
        <a-table-column title="项目阶段确认时间" data-index="approvedDate"> </a-table-column>
        <a-table-column title="当年确认合同额" align="right">
          <template slot-scope="text">
            <Money :money="text.projectPureAmount" />
          </template>
        </a-table-column>
        <a-table-column title="是否历史阶段">
          <template slot-scope="text">
            <span v-if="text.legacy == '1'">是</span>
            <span v-else>否</span>
          </template>
        </a-table-column>
        <a-table-column title="备注" data-index="remarks"> </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="javascript:void(0)" @click="editRow(text)">修改</a>
            <a href="javascript:void(0)" v-if="isContractAdmin && text.staffConfigureStatus == 'approved' && text.approvedDate && (text.approvedDate).includes((new Date()).getFullYear())" @click="rollbackRow(text)" style="margin-left: 10px;">回退</a>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <a-modal
      title="修改"
      :visible="visible" :footer="null" @cancel="visible = false"
    >
      <a-textarea v-model="detail.remarks" placeholder="备注" />
      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="visible = false">取消</a-button>
          <a-button type="primary" :loading="loading" @click="sure"
            >提交</a-button
          >
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Title from "../title";
import request from "@/api/request";
function updateRemark(data) {
  return request({
    url: "/project-service/progress/updateRemark",
    data,
    method: "POST"
  });
}
function progressBack(id) {
  return request({
    url: "/project-service/progress/staff/back/" + id,
    method: "POST"
  });
}
export default {
  components: {
    Title
  },
  data() {
    return {
      visible: false,
      loading: false,
      detail: {
        id: null,
        remarks: ''
      }
    }
  },
  computed: {
    ...mapState("projectDetail", ["phaseList"]),
    // 用户角色
    ...mapGetters("auth", ["isContractAdmin"]),
  },

  methods: {
    ...mapActions("projectDetail", [
      "fetchPhaseList",
    ]),
    editRow(text) {
      this.visible = true;
      this.detail = {
        id: text.id,
        remarks: text.remarks
      }
    },
    rollbackRow(text) {
      const that = this;
      this.$confirm({
        title: "确认要回退吗？",
        onOk() {
          progressBack(text.id).then(()=>{
            that.fetchPhaseList();
          })
        },
      });
    },
    sure() {
      this.loading = true;
      updateRemark({
        id: this.detail.id,
        remarks: this.detail.remarks
      }).then(res => {
        console.log(res);
        this.loading = false;
        this.visible = false;
        this.fetchPhaseList();
      })
    }
  }
};
</script>